@import "../../layout";

#signin {
    @include center;
    width: 100%;

    #signin-form {
        @include column(center, null, 12px);

        #signin-remember-me-container {
            margin: 8px auto;
        }

        #signin-forgot-password {
            @include row(null, center, 5px);
            margin-top: 12px;
            font-size: 14px;

            a {
                text-decoration: none;
                cursor: pointer;
            }
        }

        #signin-social {
            #signin-social-separator {
                @include row(center, space-between, 16px);
                font-size: 18px;
                font-weight: 600;
                margin-top: 12px;

                hr {
                    border: 0px;
                    max-width: 20vw;
                    width: 96px;
                    height: 1px;

                    @include themed() {
                        background-color: t('link-color');
                    }
                }
            }

            #signin-social-buttons {
                @include row(center, space-evenly);
                margin-top: 24px;

                button {
                    //width: 88px;
                    width: 120px;
                    
                    @include themed() {
                        background-color: t('color3');
                        border: 1px solid rgba(mix(t('color5'), t('color3')), 0.3); // #353f39
                    }
                }

                @media only screen and (min-width: 480px) {
                    button {
                        border-radius: 16px;
                        height: 60px;
                    }
                }

                @media only screen and (max-width: 480px) {
                    button {
                        border-radius: 12px;
                        height: 40px;
                        padding: 0px;
                        padding-top: 2px;

                        img {
                            width: 18px;
                        }
                    }
                }
            }
        }

        #signin-signup-text {
            @include row(null, center, 5px);
            font-size: 14px;

            @media only screen and (min-width: 480px) {
                margin-top: 30px;
            }

            @media only screen and (max-width: 480px) {
                margin-top: 12px;
            }

            a {
                text-decoration: none;
                cursor: pointer;
            }
        }

    }
}