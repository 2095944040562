@import "../../layout";

#passify-navbar {
    position: fixed;
    left: 0px;
    z-index: 6;

    &-container {
        @include center;
        position: relative;
        width: 100%;
        height: 100%;
    }

    &-overlay, &-content {
        position: absolute;
    }

    @media only screen and (min-width: 480px) {
        top: 0px;
        width: 64px;
        height: 100vh;

        &-overlay {
            display: none;
        }

        &-content {
            width: 100%;
            height: 100%;
        }
    }

    @media only screen and (max-width: 480px) {
        bottom: 0px;
        width: 100%;
        height: 64px;

        &-overlay {
            width: 100%;
            height: 100%;
            mask-image: linear-gradient(transparent, rgba(0, 0, 0, 0.7), #000);
            backdrop-filter: blur(1px);

            @include themed() {
                background-color: rgba(t('color4'), 0.4);
            }
        }

        &-content {
            bottom: 12px;
            width: calc(100% - 32px);
            height: 48px;
            border: 1px solid rgba($primary, 0.2);
            border-radius: 16px;
            background-color: rgba($primary, 0.2);
            backdrop-filter: blur(5px);

            @include themed() {
                background-image: linear-gradient(60deg, rgba(t('color3'), 0.4), rgba(t('color4'), 0.4));
            }
        }
    }
}

#passify-menu {
    width: 100%;
    height: 100%;
    margin: 0px;

    @media only screen and (min-width: 480px) {
        @include column(center, flex-start, 16px);
        width: 48px;
        padding: 12.5vh 8px 8px 8px;
        border-right: 1px solid $primary;
        background-color: rgba($primary, 0.1);
        backdrop-filter: blur(5px);
    
        .passify-menu-item {
            position: relative;
            display: flex;
            width: 32px;
            padding: 8px;
            cursor: pointer;

            @include themed() {
                color: t('color5');
            }
    
            svg {
                transition: transform 0.5s cubic-bezier(.47,1.64,.41,.8);
                font-size: 28px;
                * {
                    @include themed() {
                        fill: t('color5');
                    }
                }
            }
    
            &-label {
                position: absolute;
                left: 64px;
                z-index: 1;
                font-size: 0px;
                padding: 5px;
                border-radius: 5px;
                background-color: transparent;
                color: #fff;
                font-weight: bold;
                text-align: left;
                white-space: nowrap;
                transition: all 0.5s cubic-bezier(.47,1.64,.41,.8);
            }
    
            &:hover {
                color: #fff;
                svg * { fill: #fff; }
    
                .passify-menu-item-label {
                    font-size: 16px;
                    background-color: rgba($primary, 0.1);
                    backdrop-filter: blur(5px);
                }
            }
        }
    
        &-item-events {
            &:hover svg {
                transform: rotate(5deg) scale(1.5);
            }
        }
    
        &-item-tickets {
            svg {
                transform: rotate(-20deg);
            }
    
            &:hover svg {
                transform: rotate(-45deg) scale(1.5);
            }
        }
    
        &-item-sales {
            svg {
                transform: rotate(-5deg);
            }
    
            &:hover svg {
                transform: rotate(-20deg) scale(1.5);
            }
        }
    
        &-item-entrances {
            &:hover svg {
                transform: scale(1.5);
            }
        }
    }

    @media only screen and (max-width: 480px) {
        @include row(center, space-evenly);
        list-style-type: none;
        width: 100%;
        padding: 0px;
        overflow: hidden;

        .passify-menu-item {
            position: relative;
            display: flex;
            cursor: pointer;

            @include themed() {
                color: t('color5');
            }
    
            svg {
                transition: transform 0.5s cubic-bezier(.47,1.64,.41,.8);
                font-size: 20px;
                * {
                    @include themed() {
                        fill: t('color5');
                    }
                }
            }
    
            &-label {
                position: absolute;
                left: 64px;
                z-index: 1;
                font-size: 0px;
                padding: 5px;
                border-radius: 5px;
                background-color: transparent;
                color: #fff;
                font-weight: bold;
                text-align: left;
                white-space: nowrap;
                transition: all 0.5s cubic-bezier(.47,1.64,.41,.8);
            }
    
            &:hover {
                color: #fff;
                svg * { fill: #fff; }
    
                .passify-menu-item-label {
                    font-size: 16px;
                    backdrop-filter: blur(5px);

                    @include themed() {
                        background-color: rgba(t('link-color'), 0.1);
                    }
                }
            }
        }
    
        &-item-events {
            &:hover svg {
                transform: rotate(5deg) scale(1.5);
            }
        }
    
        &-item-tickets {
            svg {
                transform: rotate(-20deg);
            }
    
            &:hover svg {
                transform: rotate(-45deg) scale(1.5);
            }
        }
    
        &-item-sales {
            svg {
                transform: rotate(-5deg);
            }
    
            &:hover svg {
                transform: rotate(-20deg) scale(1.5);
            }
        }
    
        &-item-entrances {
            &:hover svg {
                transform: scale(1.5);
            }
        }
    }
}