@import "../../../layout";

.form-input-text {
    @include row(center, space-between, 14px);
    max-width: calc(100vw - 72px);
    width: 380px;
    height: 60px;
    border-radius: 16px;
    padding: 0px 20px 0px 20px;
    cursor: text;
    
    @include themed() {
        background-color: rgba(t('color3'), 0.7);
        background-image: linear-gradient(60deg, rgba(t('color2'), 0.15), rgba(t('color2'), 0));
    }

    input {
        width: 100%;
        background-color: transparent;
        font-size: 14px;
        font-weight: 400;
        outline: 0px;
        border: 0px;

        @include themed() {
            color: t('color5');
    
            &::placeholder {
                color: rgba(t('color5'), 0.6);
            }
        }

        &:autofill, &:-webkit-autofill, &:autofill:focus, &:-webkit-autofill:focus {
            border: 0px;
            transition: background-color 600000s 0s, color 600000s 0s;
            background-color: transparent !important;

            @include themed() {
                color: t('color5') !important;
            }
        }

        &[data-autocompleted] {
            background-color: transparent !important;

            @include themed() {
                color: t('color5') !important;
            }
        }
    }
}