@import "./layout";
@import url('https://fonts.googleapis.com/css2?family=Orbitron:wght@100;400;600;700&display=swap');

$header-size: 64px;

#passify {
	@include column(center, flex-start, 32px);
	
	@include themed() {
		color: t('color5');
		background: linear-gradient(60deg, t('color3'), t('color4'));
    }

	@media only screen and (min-width: 480px) {
		width: calc(100% - 65px);
		min-height: 100vh;
		padding-left: 65px;
	}

	@media only screen and (max-width: 480px) {
		width: 100%;
		min-height: calc(100vh - 64px);
		padding-bottom: 64px;
	}

	#tos-disclaimer {
        @include center;
        position: fixed;
        top: 0px;
        left: 0px;
        z-index: 4;
        width: 100%;
        height: 100vh;
        backdrop-filter: blur(5px);

        @include themed() {
            background-color: rgba(t('color5'), 0.15);
        }

        #tos-disclaimer-container {
            @include column(center, center, 16px);
            max-width: 400px;
            width: 60vw;
            padding: 24px;
            border-radius: 0.5em;

            @include themed() {
                background-color: t('color3');
            }

            @media only screen and (max-width: 480px) {
                margin-bottom: 60px;
            }
        }

		#tos-disclaimer-title {
			font-weight: bold;
			font-size: 13px;
		}

		#tos-disclaimer-text {
			text-align: center;
			font-size: 11px;
		}
    }

	header#passify-header {
		@include row(center, space-between, 12px);
		max-width: 1184px;
		width: calc(100% - 32px);
		padding: 0px 16px;
		margin-top: 16px;

		.passify-header-corner {
			@include row(center, center, 8px);
			font-size: 14px;

			.mobile,
			.desktop {
				@include row(center, center, 8px);
				font-size: 14px;
			}

			.mobile {
				@media only screen and (min-width: 480px) {
					display: none;
				}

				@media only screen and (max-width: 480px) {
					display: flex;
				}
			}

			.desktop {
				@media only screen and (min-width: 480px) {
					display: flex;
				}

				@media only screen and (max-width: 480px) {
					display: none;
				}
			}
		}

		h1 {
			margin: 0px;
		}
	}

	#passify-body {
		width: 100%;
		margin: 16px 0px;
	}
}