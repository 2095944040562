@import "../../layout";

#events {
    @include column(center, null, 12px);
    max-width: 748px;
    margin: 0px auto;
    padding: 0 16px;

    #events-header {
        width: 100%;

        @media only screen and (min-width: 480px) {
            @include row(center, space-between);
        }

        @media only screen and (max-width: 480px) {
            @include column(center, null, 12px);
        }
        

        #events-header-text {
            width: 100%;
            text-align: left;
            font-size: 18px;
            font-weight: 700;
        }

        #events-header-filter {
            width: 260px;
            min-width: 260px;
        }
    }

    #events-timeline {
        width: 100%;

        #events-timeline-new {
            border-radius: 0.5em;
            box-shadow: 0px 0px 9px 3px rgba(#000, 0.2);
            overflow: hidden;

            @include themed() {
                background-color: rgba(t('color3'), 0.9);
            }

            button {
                @include row(center, center, 8px);
                border: 0px;
                outline: 0px;
                background-color: transparent;
                font-weight: 700;

                @include themed() {
                    color: t('color5');
                }

                .icon {
                    @include themed() {
                        color: t('link-color');
                    }
                }
            }
        }

        .events-timeline-item {
            width: calc(100% - 24px);
            border-radius: 12px;
            padding: 12px;
            box-shadow: 0px 0px 9px 3px rgba(#000, 0.2);
            cursor: pointer;

            @media only screen and (min-width: 480px) {
                @include row(center, space-between);
            }
    
            @media only screen and (max-width: 480px) {
                @include column(center, null, 8px);
            }

            @include themed() {
                background-color: rgba(t('color3'), 0.8);
            }

            &.past {
                filter: grayscale(95%);

                @include themed() {
                    background-color: rgba(t('color3'), 0.5);
                }
            }

            .events-timeline-item-responsive-group {
                @include row(center, flex-start, 12px);

                @media only screen and (max-width: 480px) {
                    width: 100%;
                }
            }

            .events-timeline-item-image {
                @include avatar(80px, 12px);
            }

            .events-timeline-item-details {
                @include column(flex-start, space-between);
                height: 64px;

                .events-timeline-item-details-title {
                    width: 100%;
                    font-size: 14px;
                    font-weight: bold;
                }

                .events-timeline-item-details-date,
                .events-timeline-item-details-location {
                    @include row(center, flex-start, 8px);
                    width: 100%;
                    font-size: 12px;

                    .icon {
                        @include themed() {
                            color: t('link-color');
                        }
                    }
                }
            }

            .events-timeline-item-cta {
                @include row(center, center, 4px);
                width: 100%;
                margin: 0px auto;
                display: none; //remove

                button {
                    @include center;
                    aspect-ratio: 1;
                    padding: 0px;
                    margin: 0px;
                    border-radius: 50%;
                    outline: 0px;
                    background-color: transparent;
                    
                    @include themed() {
                        border: 1px solid $primary;
                        color: $primary;
                    }
                }
            }
        }
    }
}