@import "../../layout";

.admission-card-container {
    display: grid;
    grid-template-columns: 100px auto;
    grid-template-areas: "user body" "user footer";
    gap: 8px;
    width: 592px;
    height: 100px;
    padding: 12px;
    border-radius: 24px;

    @include themed() {
        background-color: rgba(t('color3'), 0.9);
    }

    .admission-card-user {
        grid-area: user;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 8px;
        width: 100%;
        font-size: 14px;

        .admission-card-user-avatar {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 64px;
            height: 64px;
            border-radius: 32px;
            overflow: clip;

            @include themed() {
                background-color: t('color2');
            }

            img {
                width: 64px;
                height: 64px;
                object-fit: cover;
            }

            svg {
                font-size: 32px;
                * {
                    @include themed() {
                        fill: rgba(t('color5'), 0.7);
                    }
                }
            }
        }

        .admission-card-user-name {
            width: 100px;
            text-align: center;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            cursor: default;
        }
    }

    .admission-card-body {
        grid-area: body;
        display: flex;
        align-items: center;
        gap: 16px;
        margin-left: 16px;
        width: 100%;

        .admission-card-ticket-details {
            display: flex;
            align-items: flex-start;
            gap: 8px;
            width: 100%;

            & > div {
                display: flex;
                flex-direction: column;
                gap: 8px;
                min-width: 128px;
            }

            .admission-card-ticket-details-label {
                display: flex;
                align-items: center;
                gap: 10px;
                color: $primary;
                font-size: 14px;
                font-weight: bold;

                svg {
                    font-size: 16px;
                }
            }

            .admission-card-ticket-details-body {
                margin-left: 4px;
                font-size: 11px;
            }
        }
    }

    .admission-card-footer {
        grid-area: footer;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 16px;
        width: 100%;

        button {
            transform: scale(0.8);
            white-space: nowrap;
        }
    }
}