@import "../../layout";

.calendar {
    .calendar-header {
        @include row(center, space-between);
        width: calc(100% - 20px);
        padding: 4px 10px;

        &.hide {
            display: none;
        }

        .calendar-current {
            @include row(center, null, 6px);
            font-size: 16px;
            font-weight: 700;

            @include themed() {
                color: t('link-color');
            }

            .calendar-current-month {
                text-transform: capitalize;
            }
        }

        .calendar-nav {
            @include row(center);
            white-space: nowrap;

            button {
                @include center;
                width: 16px;
                height: 16px;
                padding: 0px;
                margin: 0px;
                border: 0px;
                outline: 0px;
                background-color: transparent;
                
                @include themed() {
                    color: t('color5');
                }
            }
        }
    }

    .calendar-row,
    .calendar-month-row,
    .calendar-year-row {
        animation: appear 0.3s ease-out forwards;
    }

    .calendar-row {
        display: grid;
        grid-template-columns: repeat(7, minmax(0, 1fr));
        align-items: center;
        font-size: 14px;

        .calendar-weekday {
            @include center;
            aspect-ratio: 1;

            @include themed() {
                color: mix(t('color5'), t('link-color'));
            }
        }

        .calendar-day {
            @include center;
            aspect-ratio: 1;

            @include themed() {
                color: t('color5');
            }

            &.not-current-month {
                @include themed() {
                    color: rgba(t('color5'), 0.6);
                }
            }

            &.range {
                @include themed() {
                    background-color: rgba(t('link-color'), 0.2);
                }
            }

            div {
                @include center;
                width: calc(100% - 4px);
                height: calc(100% - 4px);
                border-radius: 0.5em;
                margin: 2px;
            }

            &:hover div {
                @include themed() {
                    background-color: rgba(t('color5'), 0.6);
                }
            }

            .selected, div.selected {
                @include themed() {
                    background-color: rgba(t('link-color'), 0.6);
                }
            }
        }
    }

    .calendar-year-header {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        align-items: center;
        font-size: 14px;

        .calendar-year-range {
            @include row(center, null, 8px);
            font-size: 12px;
            border-radius: 0.5em;
            margin: 2px;
            aspect-ratio: 3;

            @include themed() {
                color: t('color5');
            }

            &:first-child {
                justify-content: flex-start;
                padding-left: 8px;
            }

            &:last-child {
                justify-content: flex-end;
                padding-right: 8px;
            }

            &:hover {
                @include themed() {
                    background-color: rgba(t('color5'), 0.6);
                }
            }
        }
    }

    .calendar-month-row,
    .calendar-year-row {
        display: grid;
        grid-template-columns: repeat(3, minmax(0, 1fr));
        align-items: center;
        font-size: 14px;

        .calendar-month {
            text-transform: capitalize;
        }

        .calendar-month,
        .calendar-year {
            @include center;
            aspect-ratio: 3 / 2;

            @include themed() {
                color: t('color5');
            }

            &.range {
                @include themed() {
                    background-color: rgba(t('link-color'), 0.2);
                }
            }

            div {
                @include center;
                width: calc(100% - 4px);
                height: calc(100% - 4px);
                border-radius: 0.5em;
                margin: 2px;
            }

            &:hover div {
                @include themed() {
                    background-color: rgba(t('color5'), 0.6);
                }
            }

            .selected, div.selected {
                @include themed() {
                    background-color: rgba(t('link-color'), 0.6);
                }
            }
        }
    }
}

@keyframes appear {
    from {
        opacity: 0;
        transform: translateY(15%);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}