@import "../../layout";

.screen-loader {
    @include column(center, center, 8px);
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100vh;
    backdrop-filter: blur(5px);

    .screen-loader-logo {
        width: 70px;
    }

    .screen-loader-progressbar {
        position: relative;
        width: 18px;
        height: 18px;

        .screen-loader-progressbar-circle {
            position: absolute;
            right: 0px;
            width: 100%;
            height: 100%;
            border: solid 3px transparent;
            border-top-color:  $primary;
            border-right-color:  $primary;
            border-radius: 50%;
            transform: rotate(135deg);  
            animation: spin 0.6s infinite;
        }
    }
}