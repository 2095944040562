@import "../../layout";

#sales {
    width: 100%;

    @media only screen and (min-width: 1280px) {
        @include row(flex-start, center, 16px);
    }

    @media only screen and (max-width: 1280px) {
        @include column(center, center, 16px);
    }

    button {
        font-size: 12px;
    }

    #sales-header {
        @include row(center, center, 24px);
        align-self: flex-start;
        font-size: 24px;
        font-weight: bold;

        #sales-header-image {
            @include circle-avatar(96px);
        }

        #sales-header-content {
            @include column(flex-start, center, 12px);

            .dashboard-text {
                text-transform: uppercase;
                font-size: 14px;
                opacity: 0.6;
            }
        }
    }

    #sales-left {
        @media only screen and (min-width: 1280px) {
            position: relative;
            width: 592px;
        }

        @media only screen and (max-width: 1280px) {
            @include center;
            max-width: 592px;
            width: calc(100vw - 96px);
        }
    
        @media only screen and (max-width: 480px) {
            @include center;
            width: calc(100vw - 32px);
        }
    }

    #sales-chart {
        @include column(center, center, 12px);

        @media only screen and (min-width: 1280px) {
            position: fixed;
            width: 592px;
        }

        @media only screen and (max-width: 1280px) {
            width: 100%;
        }

        #sales-chart-mode-selector {
            min-width: 90px;
        }

        #sales-chart-resolution-selector {
            @include row(center, space-evenly, 12px);
            width: 100%;

            & > div:first-child {
                width: 90px;
            }

            & > div:last-child {
                max-width: 318px;
                width: 100%;
            }
        }

        #sales-chart-info {
            @include column(center, center);
            font-size: 16px;
            margin: 12px 0px;
        
            @media only screen and (max-width: 640px) {
                width: 100%;
                font-size: 14px;
            }

            .value {
                font-size: 22px;
            }

            .quantity {
                font-size: 14px;
                opacity: 0.6;
            }
        }
    }

    #sales-list {
        @include column(center, center, 16px);
        
        @media only screen and (min-width: 1280px) {
            width: 592px;
        }

        @media only screen and (max-width: 1280px) {
            max-width: 592px;
            width: calc(100vw - 96px);
        }
    
        @media only screen and (max-width: 480px) {
            width: calc(100vw - 32px);
        }

        #sales-list-export {
            width: 100%;
            font-size: 14px;
            text-align: right;
        }

        #sales-list-settings {
            @include column(center, center, 12px);
            
            width: 100%;
            max-width: 592px;

            #sales-list-settings-type {
                @media only screen and (min-width: 640px) {
                    @include row(center, space-between, 12px);
                }
            
                @media only screen and (max-width: 640px) {
                    @include column(center, center, 12px);
                }
            }
    
            #sales-list-settings-search-container {
                width: 100%;

                @media only screen and (min-width: 480px) {
                    max-width: calc(100vw - 96px);
                }

                @media only screen and (max-width: 480px) {
                    max-width: calc(100vw - 32px);
                }
    
                .form-input-text {
                    border-radius: 0.5em;
                    width: calc(100% - 40px);
                    height: 32px;
                }
            }
        }
    
        #sales-list-customers {
            @include column(center, center, 12px);
            width: 100%;
        }

        #sales-list-pagination {
            @include center;
            width: calc(100% - 32px);
            max-width: 592px;
        }
    }
}