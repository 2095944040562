@import "../../../layout";

.password-form-input {
    @include column(flex-start);

    .label {
        margin-top: 12px;
        padding-left: 16px;
        font-size: 12px;
        padding-bottom: 6px;
    }
}

.password-requirements {
    @include column(flex-start);
    font-size: 14px;
    height: 0px;
    overflow: hidden;
    transition: all 0.2s linear;

    &.open {
        height: var(--password-requirements-size);
        margin-bottom: 0px;
    }

    & > div {
        height: 18px;
    }

    .requirement-valid, .requirement-invalid, .requirement-recommended {
        @include row(center, null, 8px);
    }

    .requirement-valid {
        @include themed() {
            color: t('success-color');
        }
    }

    .requirement-recommended {
        @include themed() {
            color: t('warning-color');
        }

        & > * {
            padding-left: 5px;
            padding-right: 1px;
        }
    }

    .requirement-invalid {
        @include themed() {
            color: t('error-color');
        }

        & > * {
            padding-right: 1px;
        }
    }
}