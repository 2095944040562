@import "../../layout";

.queue-clock {
    @include row(center, center, 8px);
    position: relative;
    border-radius: 0.5em;
    padding: 6px 12px;
    min-width: 60px;

    @include themed() {
        background-color: rgba(t('color3'), 0.9);
        color: t('link-color');
    }

    &.fixed {
        position: fixed;
        top: 0px;
    }

    &-text {
        font-size: 14px;
        font-weight: bold;

        @include themed() {
            color: t('color5');
        }
    }
}