@import "../../layout";

.heatmap-table {
    display: grid;
    align-items: center;
    width: var(--heatmap-width);
    height: var(--heatmap-height);
    grid-template-columns: repeat(25, minmax(0, 1fr));
    grid-template-rows: repeat(8, minmax(0, 1fr));
    font-size: calc(0.555 * min(calc(var(--heatmap-width) / 25 - 2px), calc(var(--heatmap-height) / 8 - 2px)));

    .heatmap-table-axis-label {
        text-transform: capitalize;
        text-align: center;
    }

    .heatmap-table-item {
        margin: 1px;
        border-radius: 0.5em;
        width: 100%;
        height: 100%;
        aspect-ratio: 1;
        transition: all 0.2s ease-out;

        @include themed() {
            background-color: t('link-color');
        }

        &:hover {
            transform: scale(1.1);
        }
    }

    .heatmap-table-axis-label,
    .heatmap-table-item {
        max-width: calc(var(--heatmap-width) / 25 - 2px);
        max-height: calc(var(--heatmap-height) / 8 - 2px);
    }
}