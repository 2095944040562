@import "../../layout";

.ticket-sold-card-container {
    display: grid;
    grid-template-columns: 100px auto;
    grid-template-areas: "user body" "user footer";
    gap: 8px;
    width: calc(100% - 32px);
    max-width: calc(592px - 32px);
    height: 100px;
    padding: 12px;
    border-radius: 24px;

    @include themed() {
        background-color: rgba(t('color3'), 0.9);
    }

    .ticket-sold-card-user {
        grid-area: user;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 8px;
        width: 100%;
        font-size: 14px;

        .ticket-sold-card-user-avatar {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 64px;
            height: 64px;
            border-radius: 32px;
            overflow: clip;

            @include themed() {
                background-color: t('color2');
            }

            img {
                width: 64px;
                height: 64px;
                object-fit: cover;
            }

            svg {
                font-size: 32px;
                * {
                    @include themed() {
                        fill: rgba(t('color5'), 0.7);
                    }
                }
            }
        }

        .ticket-sold-card-user-name {
            width: 100px;
            text-align: center;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            cursor: default;
        }
    }

    .ticket-sold-card-body {
        grid-area: body;
        display: flex;
        align-items: center;
        gap: 16px;
        margin-left: 16px;
        width: 100%;

        .ticket-sold-card-ticket-details {
            display: flex;
            align-items: flex-start;
            gap: 8px;
            width: 100%;

            & > div {
                display: flex;
                flex-direction: column;
                gap: 8px;
                min-width: 128px;
            }

            .ticket-sold-card-ticket-details-label {
                display: flex;
                align-items: center;
                gap: 10px;
                font-size: 14px;
                font-weight: bold;

                @include themed() {
                    color: t('link-color');
                }

                svg {
                    font-size: 16px;
                }
            }

            .ticket-sold-card-ticket-details-body {
                margin-left: 4px;
                font-size: 11px;
            }
        }
    }

    .ticket-sold-card-footer {
        grid-area: footer;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 16px;
        width: 100%;

        button {
            transform: scale(0.8);
            white-space: nowrap;
        }
    }
}

.ticket-sold-card {
    @include row(center, flex-start, 16px);
    width: calc(100% - 32px);
    max-width: calc(592px - 32px);
    height: 48px;
    padding: 4px 16px;
    border-radius: 12px;
    cursor: pointer;

    @include themed() {
        background-color: rgba(t('color3'), 0.9);
    }

    .ticket-sold-card-user {
        @include row(center, flex-start, 16px);
        width: 100%;
        height: 32px;

        .ticket-sold-card-user-avatar {
            @include circle-avatar(32px);
    
            @include themed() {
                background-color: t('color2');
            }
    
            svg {
                font-size: 16px;
                * {
                    @include themed() {
                        fill: rgba(t('color5'), 0.7);
                    }
                }
            }
        }
    
        .ticket-sold-card-user-info {
            @include column(flex-start, center, 2px);
            width: 100%;

            .ticket-sold-card-user-info-name,
            .ticket-sold-card-user-info-email,
            .ticket-sold-card-user-info-phone {
                @include row(center, null, 8px);
                width: 100%;

                @media only screen and (min-width: 480px) {
                    max-width: calc(100vw - 96px);
                }
        
                @media only screen and (max-width: 480px) {
                    max-width: 175px;
                    white-space: nowrap;
                    text-overflow: clip;
                    mask-image: linear-gradient(90deg, #000 75%, transparent);
                }

                i {
                    opacity: 0.6;
                }

                .icon {
                    @include themed() {
                        color: t('link-color');
                    }
                }
            }

            .ticket-sold-card-user-info-email,
            .ticket-sold-card-user-info-phone {
                font-size: 13px;
            }

            .ticket-sold-card-user-info-email {
                font-style: italic;
                opacity: 0.8;
            }
        }
    }

    .ticket-sold-card-sale {
        @include column(flex-end);
        margin-left: auto;
        font-size: 12px;

        .ticket-sold-card-sale-quantity {
            white-space: nowrap;
            opacity: 0.4;
        }
    
        .ticket-sold-card-sale-financial {
            white-space: nowrap;
            opacity: 0.6;
        }

        .ticket-sold-card-sale-segment {
            @include column(flex-start, null, 4px);
            width: 100%;

            .ticket-sold-card-sale-segment-name {
                @include themed() {
                    color: t('link-color');
                }
            }

            .ticket-sold-card-sale-segment-batch {
                @include row(center, space-between, 16px);
                width: 100%;

                .ticket-sold-card-sale-segment-batch-bought {
                    width: 100%;

                    .light {
                        opacity: 0.6;
                    }

                    .promo-code {
                        border-radius: 0.5em;
                        font-size: 10px;
                        font-weight: 700;
                        padding: 3px 8px;

                        @include themed() {
                            background-color: t('link-color');
                        }
                    }
                }

                .ticket-sold-card-sale-segment-batch-amount {
                    text-align: right;
                    white-space: nowrap;
                    opacity: 0.8;
                }
            }
        }

        .ticket-sold-card-sale-payment {
            @include column(flex-start, null, 4px);
            width: 100%;

            .ticket-sold-card-sale-payment-caption {
                @include themed() {
                    color: t('link-color');
                }
            }
    
            .ticket-sold-card-sale-payment-method {
                @include row(center, null, 8px);

                .light {
                    opacity: 0.6;
                }
            }
        }

        .ticket-sold-card-sale-transfer-history {
            @include column(flex-start, null, 4px);
            width: 100%;

            .ticket-sold-card-sale-transfer-history-caption {
                @include themed() {
                    color: t('link-color');
                }
            }

            .ticket-sold-card-sale-transfer-history-list {
                @include column(null, null, 4px);
                width: 100%;

                .ticket-sold-card-sale-transfer-item {
                    @include row(center, center, 12px);
                    width: 100%;
    
                    svg {
                        font-size: 16px;
    
                        @include themed() {
                            color: t('link-color');
                        }
                    }
    
                    .ticket-sold-card-sale-transfer-item-peer {
                        white-space: nowrap;
                    }
    
                    .ticket-sold-card-sale-transfer-item-time {
                        @include row(center, flex-end, 4px);
                        width: 100%;
                    }
                }
            }
        }

    }

    &.invalid {
        filter: grayscale(0.95);

        @include themed() {
            color: rgba(t('color5'), 0.5);
        }

        .ticket-sold-card-user-avatar {
            opacity: 0.75;
        }
    }

    &.expanded {
        @include column(flex-start, null, 8px);
        height: auto;
        min-height: 80px;

        .ticket-sold-card-user {
            height: auto;
            min-height: 64px;
    
            @media only screen and (max-width: 480px) {
                @include column(center, center, 12px);
                margin-top: 12px;
            }

            .ticket-sold-card-user-avatar {
                @include circle-avatar(64px);
            }

            .ticket-sold-card-user-info {
                @media only screen and (max-width: 480px) {
                    width: auto;
                }

                .ticket-sold-card-user-info-name,
                .ticket-sold-card-user-info-email,
                .ticket-sold-card-user-info-phone {
                    @media only screen and (min-width: 480px) {
                        max-width: calc(100vw - 64px);
                    }
            
                    @media only screen and (max-width: 480px) {
                        max-width: 296px;
                        text-overflow: ellipsis;
                        mask-image: none;
                    }
                }

                .ticket-sold-card-user-info-name {
                    @media only screen and (max-width: 480px) {
                        justify-content: center;

                        .icon {
                            display: none;
                        }
                    }
                }

                .ticket-sold-card-user-info-email {
                    font-style: none;
                    opacity: 1;
                }
            }
        }

        .ticket-sold-card-sale {
            @include column(flex-start, null, 12px);
            width: calc(100% - 24px);
            margin: 12px;
            font-size: 12px;
        }
    }
}