@import "../../layout";

@mixin single-featured-event-card {
    @include row(flex-end, center);
    position: relative;
    z-index: 2;
    width: calc(100% - 32px);
    height: calc(280px + 1.6em);
    border-radius: 0.8em;
    overflow: hidden;
    cursor: pointer;

    @include themed() {
        background-color: rgba(t('color3'), 0.9);
        box-shadow: 0px 0px 12px 4px rgba(#000, 0.2);
    }

    &-image {
        position: absolute;
        top: 0px;
        left: 0px;
        z-index: 1;
        width: 100%;
        height: calc(280px + 1.6em);
        overflow: hidden;

        img {
            width: 100%;
            min-height: calc(280px + 1.6em);
            object-fit: cover;
        }
    }

    &-details {
        @include column(flex-start, center, 0.5em);
        z-index: 2;
        width: calc(100% - 24px);
        padding: 6px 12px;
        
        @include themed() {
            background: linear-gradient(to bottom, rgba(t('color3'), 0), rgba(t('color3'), 0.6), rgba(t('color3'), 0.8), rgba(t('color3'), 0.9));
        }

        &-name {
            width: 100%;
            margin-top: calc(64px + 0.25em);
            font-size: 18px;
            font-weight: bold;
            white-space: nowrap;
            text-overflow: clip;
            mask-image: linear-gradient(90deg, #000 75%, transparent);
            text-align: left;
        }
    
        &-date {
            font-size: 16px;
        }
    
        &-location {
            font-size: 14px;
        }
    
        &-date, &-location {
            @include row(center, flex-start, 8px);
    
            .icon {
                @include themed() {
                    color: t('link-color');
                }
            }
        }
    }

}

.single-featured-event-card {
    @include single-featured-event-card;
}