@import "../../layout";

.pagination {
    --extra-width: 0px;
    --arrow-width: calc(15px + var(--extra-width));

    @include row(center, center, 0px);
    width: var(--size);
    height: 32px;
    font-size: 14px;
    border-radius: 0.5em;

    &.show-text {
        --extra-width: 76px;
    }

    @include themed() {
        background-color: rgba(t('color3'), 0.9);
    }

    &-prev, &-next {
        @include row(center, center, 8px);
        width: var(--arrow-width);
        height: 100%;
        padding: 0 12px;
        cursor: default;

        @include themed() {
            &.disabled {
                color: rgba(t('color5'), 0.6);
            }
        }
    }

    &-separator {
        width: 1px;
        height: calc(100% - 12px);

        @include themed() {
            background-color: rgba(t('color5'), 0.8);
        }
    }

    &-track {
        --track-width: calc(var(--size) - calc(50px + 2 * var(--arrow-width)));
        --cursor-width: 32px;

        @include row(center, flex-start);
        padding: 0.5em;
        width: var(--track-width);
        height: 100%;
        overflow: hidden;
        mask-image: linear-gradient(to left, transparent, black, transparent);

        &-body {
            @include row(center, flex-start, 0px);
            height: calc(100% - 8px);
        }

        &-item {
            @include center;
            height: 100%;
            width: var(--cursor-width);
            cursor: default;
            transition: all 0.2s ease-out;
        }

        &-current {
            font-weight: bold;
            border-radius: 0.5em;

            @include themed() {
                background-color: rgba(mix(t('color3'), t('color4')), 0.7);
            }
        }
    }
}