@import "../../layout.scss";

.button-slider-switch {
    border-radius: 0.5em;
    padding: 4px;

    @include themed() {
        background-color: rgba(t('color5'), 0.1);
    }

    .button-slider-switch-container {
        position: relative;
        z-index: 1;
        display: grid;
        grid-template-columns: repeat(var(--switch-length), minmax(0, 1fr));
        align-items: center;
    
        .button-slider-switch-item {
            @include center;
            position: relative;
            z-index: 3;
            width: 100%;
            background-color: transparent;
            
            button {
                @include center;
                width: calc(100% - 8px);
                border: 0px;
                background-color: transparent;
                padding: 4px;
                transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);

                @include themed() {
                    color: t('color5');
                }

                &.selected {
                    color: $primary;
                }
            }
        }

        .button-slider-switch-highlight {
            position: absolute;
            left: 0%;
            z-index: 2;
            width: calc(100% / var(--switch-length));
            height: 100%;
            border-radius: 0.5em;
            transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);

            @include themed() {
                background-color: rgba(mix(t('color3'), t('color4')), 0.7);
            }
        }
    }
}