@import "../../layout";

#event-form {
    @include column(flex-start, null, 12px);
    width: 100%;
    padding-bottom: 16px;
    border-radius: 16px;

    @include themed() {
        background-color: rgba(t('color3'), 0.9);
    }

    #event-form-click-out {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        z-index: 2;
    }

    #event-form-image {
        position: relative;
        z-index: 1;
        width: 100%;
        height: 260px;
        border-top-left-radius: 16px;
        border-top-right-radius: 16px;
        overflow: hidden;

        img {
            width: 100%;
            min-height: 260px;
            object-fit: cover;
            mask-image: linear-gradient(to bottom, rgba(#000, 0.85), transparent 260px, transparent);
        }

        #event-form-edit-image {
            position: absolute;
            z-index: 2;
            top: 16px;
            right: 16px;
            opacity: 0.8;

            @include themed() {
                color: t('link-color');
            }

            &:hover {
                opacity: 1;
            }

            svg {
                filter: drop-shadow(3px 5px 2px rgba(#000, 0.4));
            }
        }

        #event-form-name {
            position: absolute;
            bottom: 12px;
            z-index: 2;
            width: calc(100% - 32px);
            padding: 0px 16px;

            input {
                border: 0px;
                outline: 0px;
                background-color: transparent;
                font-size: 22px;
                font-weight: 700;
                transition: all 0.2s ease-out;

                @include themed() {
                    color: t('color5');
                }

                &::placeholder {
                    opacity: 0.6;

                    @include themed() {
                        color: t('color5');
                    }
                }

                &:hover::placeholder {
                    opacity: 0.8;
                }
            }
        }
    }

    #event-form-date,
    #event-form-venue {
        @include row(flex-start, null, 12px);
        width: calc(100% - 32px);
        padding: 0px 16px;

        .icon {
            @include themed() {
                color: t('link-color');
            }
        }
    }

    #event-form-date {
        #event-form-date-container {
            width: 100%;

            @media only screen and (min-width: 480px) {
                @include row(center, null, 8px);
            }
    
            @media only screen and (max-width: 480px) {
                @include column(flex-start, null, 8px);
            }

            #event-form-date-start,
            #event-form-date-end {
                @include column(flex-start, null, 4px);
                width: calc(50% - 10px);
                min-width: 220px;
                font-size: 12px;

                .event-form-date-input {
                    @include row(center, null, 2px);
                    font-size: 14px;

                    & > div {
                        position: relative;
                        text-align: center;
                        padding: 4px 12px;
                        cursor: default;

                        @include themed() {
                            background-color: t('color2');
                        }

                        @media only screen and (max-width: 480px) {
                            border-radius: 0.5em;
                        }

                        &:hover {
                            @include themed() {
                                background-color: rgba(t('color1'), 0.5);
                            }
                        }

                        .event-form-date-calendar {
                            position: absolute;
                            top: 28px;
                            left: 0px;
                            z-index: 4;
                            display: none;

                            &.open {
                                display: block;
                                
                                .calendar {
                                    opacity: 0;
                                    backdrop-filter: blur(5px);
                                    animation: open-content 0.4s ease-out forwards;
                                }
                            }

                            .calendar {
                                width: 220px;
                                padding: 4px;
                                border-radius: 0.5em;
                                box-shadow: 0px 0px 9px 3px rgba(#000, 0.2);
                                backdrop-filter: blur(5px);

                                @include themed() {
                                    border: 1px solid rgba(t('color5'), 0.4);
                                    background-color: rgba(t('color3'), 0.8);
                                }
                            }
                        }

                        .event-form-date-time-select {
                            position: absolute;
                            top: 28px;
                            left: 0px;
                            z-index: 4;
                            display: none;
                            max-height: 260px;
                            border-radius: 0.5em;
                            padding: 0px;
                            margin: 0px;
                            overflow: auto;
                            -webkit-overflow-scrolling: touch;
                            box-shadow: 0px 0px 9px 3px rgba(#000, 0.2);
                            backdrop-filter: blur(5px);

                            @include themed() {
                                border: 1px solid rgba(t('color5'), 0.4);
                                background-color: rgba(t('color3'), 0.8);
                            }

                            &.open {
                                display: block;
                                opacity: 0;
                                animation: open-content 0.4s ease-out forwards;
                            }

                            & > div {
                                width: calc(100% - 18px);
                                border-radius: 0.5em;
                                padding: 4px 8px;
                                margin: 1px;

                                &:hover {
                                    @include themed() {
                                        background-color: rgba(t('color1'), 0.4);
                                    }
                                }

                                &.selected {
                                    @include themed() {
                                        background-color: rgba(t('link-color'), 0.6)
                                    }
                                }
                            }
                        }
                    }

                    & > div:first-child {
                        width: 76px;
                    }

                    & > div:last-child {
                        width: 40px;
                    }

                    @media only screen and (min-width: 480px) {
                        & > div:first-child {
                            border-top-left-radius: 0.5em;
                            border-bottom-left-radius: 0.5em;
                        }
    
                        & > div:last-child {
                            border-top-right-radius: 0.5em;
                            border-bottom-right-radius: 0.5em;
                        }
                    }
                }
            }
        }
    }

    #event-form-venue {
        #event-form-venue-container {
            @include column(null, null, 4px);
            width: calc(100% - 24px);
            padding: 8px 12px;
            border-radius: 0.5em;
            cursor: default;

            @include themed() {
                background-color: t('color2');
            }

            &:hover {
                @include themed() {
                    background-color: rgba(t('color1'), 0.5);
                }
            }

            input {
                border: 0px;
                outline: 0px;
                border-radius: 0px;
                background-color: transparent;
                font-size: 14px;
                padding: 0px;
                transition: all 0.2s ease-out;

                @include themed() {
                    color: t('color5');
                }

                &::placeholder {
                    opacity: 0.6;

                    @include themed() {
                        color: t('color5');
                    }
                }

                &:hover::placeholder {
                    opacity: 0.8;
                }
            }

            #event-form-venue-street {
                font-size: 12px;

                input {
                    font-size: 12px;
                }
            }
        }
    }

    #event-form-description {
        @include column(flex-start, null, 8px);
        width: calc(100% - 32px);
        padding: 0px 16px;
        margin: 12px 0px;
        font-size: 12px;

        textarea {
            width: calc(100% - 24px);
            padding: 4px 12px;
            border-radius: 0.5em;
            border: 0px;
            outline: 0px;
            background-color: transparent;
            resize: none;
            cursor: default;

            @include themed() {
                color: t('color5');
                background-color: t('color2');
            }

            &:hover {
                @include themed() {
                    background-color: rgba(t('color1'), 0.5);
                }
            }
        }
    }

    #event-form-cta {
        @include center;
        padding: 0px 16px;
        width: calc(100% - 32px);
    }
}

@keyframes open-content {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}